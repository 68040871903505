import React, { useEffect, useRef, useState } from "react";
import SingleFileUpload from "../../../components/upload/SingleFileUpload";
import { Box } from "@mui/material";
import ExoForm from "../../../components/exo/ExoForm";
import Loader from "../../../components/special/Loader";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { DBUploadData, useConfig } from "../../../api/api";
import moment from "moment";

export const UploadPayroll = ({ debug, onResponse, onClose, payroll }) => {
  const { t } = useTranslation();
  const config = useConfig();

  const exoFormRef = useRef(null);
  const singeUploadRef = useRef(null);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function handleUpload(rawData) {
    const formData = new FormData();

    if (payroll && payroll.file) {
      if (file) {
        if (Object.keys(file).length) {
          formData.append(`file`, file);
        } else {
          formData.append(`file`, null);
        }
      }
    } else {
      if (file && Object.keys(file).length > 0) formData.append(`file`, file);
    }

    if (rawData.amount) formData.append(`amount`, rawData.amount);
    if (rawData.month) {
      const startDate = moment(rawData.month, "MM/YYYY")
        .startOf("M")
        .format("YYYY-MM-DD");
      const endDate = moment(rawData.month, "MM/YYYY")
        .endOf("M")
        .format("YYYY-MM-DD");

      formData.append(`startDate`, startDate);
      formData.append(`endDate`, endDate);
    }
    if (rawData.user) formData.append(`user`, JSON.stringify(rawData.user));
    if (rawData.transaction)
      formData.append(`transaction`, JSON.stringify(rawData.transaction));

    if (payroll && payroll.id) formData.append("_method", "PATCH");
    const path = payroll && payroll.id ? `payrolls/${payroll.id}` : "payrolls";
    setIsLoading(true);
    DBUploadData({
      config,
      path,
      formData: formData,
      onResponse,
      onError: () => setIsLoading(false),
    });
  }

  const validationSchema = Yup.object().shape({
    amount: Yup.string().required(t("Is required")),
    month: Yup.string().required(t("Is required")),
    user: Yup.object().shape({
      id: Yup.string().required(t("Is required")),
    }),
  });

  const fields = [
    {
      label: `${t("Transaction")} (${t("optional")})`,
      type: "transaction",
      key: "transaction",
    },
    {
      label: t("Employee"),
      type: "user",
      key: "user",
    },
    {
      label: t("Month"),
      type: "date",
      key: "month",
      views: ["month"],
      format: "MM/YYYY",
    },
    {
      label: t("Salary"),
      type: "money",
      key: "amount",
    },
    {
      label: `${t("Comment")} (${t("optional")})`,
      type: "description",
      key: "comment",
    },
  ];

  const [usedValues, setUsedValues] = useState(null);
  useEffect(() => {
    if (payroll)
      setUsedValues({
        ...payroll,
        month: moment(payroll.startDate).format("MM/YYYY"),
      });
  }, [payroll]);

  // preload file
  useEffect(() => {
    if (payroll && payroll.file) {
      const newFileData = {
        id: payroll.file.id,
        lastModified: 0,
        name: payroll.file.title,
        path: payroll.file.temporaryUrl,
        size: payroll.file.fileSize,
        type: payroll.file.mimeType,
        webkitRelativePath: "",
      };
      singeUploadRef.current.setFileData(newFileData);
    }
  }, [payroll]);

  return (
    <Box
      className="w-full h-full overflow-y-auto grid grid-cols-1 md:grid-cols-5 gap-2 relative px-2 py-4 md:px-2 md:py-2 mx-auto"
      sx={{
        maxWidth: "100%",
        width: "1200px",
      }}
    >
      <Loader active={isLoading} />
      <Box className="col-span-1 md:col-span-2 flex items-center justify-center  overflow-y-auto py-0 md:py-10 h-96 md:h-full">
        <SingleFileUpload
          debug={debug}
          ref={singeUploadRef}
          setFile={setFile}
          uploadedMedia={file}
        />
      </Box>
      <Box className="col-span-1 md:col-span-3 flex flex-col gap-4 p-0 md:p-1 rounded-lg">
        <ExoForm
          debug={debug}
          ref={exoFormRef}
          validationSchema={validationSchema}
          startDataCollection={usedValues}
          noDifferences={Boolean(payroll)}
          fields={fields}
          onSubmit={handleUpload}
          header={t("New Payroll")}
          onCancle={onClose}
          useStartDataOnReset
          submitText={payroll ? t("Update") : t("Create")}
          isNotValid={!Boolean(file && file.name)}
        />
      </Box>
    </Box>
  );
};
