import { useParams } from "react-router-dom";
import useFetch from "../../../api/useFetch";
import Header from "../../../components/special/Header";
import { useTranslation } from "react-i18next";
import { ProjectManagementWorkPackageSkeleton } from "./ProjectManagementWorkPackageSkeleton";
import CardGrid from "../../../components/cards/layout/CardGrid";
import CardContainer from "../../../components/cards/layout/CardContainer";
import RenderHTML from "../../../components/preview/RenderHTML";
import { ProjectUserStack } from "../../../components/exo/project/ProjectUserStack";
import { Box, Typography, useTheme } from "@mui/material";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { tokens } from "../../../global/theme/tokens";
import { useTimeTracking } from "../../../components/time-tracking/AppTimeProvider";
import { renderHours } from "../../time-tracking/worktime";
import { WPEMap } from "./work-package-elements/WPEMap";
import { createElement } from "react";

export const ProjectManagementWorkPackage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { clockIn, clockOut, activeProject, secondsIn } = useTimeTracking();

  const { id } = useParams();
  const { apiData, setApiData } = useFetch(`work-packages/${id}`, true);

  const { t } = useTranslation();
  if (!apiData) return <ProjectManagementWorkPackageSkeleton />;

  const workPackage = apiData.data;

  const activeWorkpackage =
    activeProject &&
    activeProject.activeWorkPackage &&
    activeProject.activeWorkPackage.id === workPackage.id
      ? {
          ...activeProject.activeWorkPackage,
          totalTimeWorkedSeconds: secondsIn
            ? activeProject.activeWorkPackage.totalTimeWorkedSeconds + secondsIn
            : activeProject.activeWorkPackage.totalTimeWorkedSeconds,
        }
      : null;

  const workPercentage = activeWorkpackage
    ? activeWorkpackage.totalTimeWorkedSeconds / activeWorkpackage.estimatedTime
    : workPackage.totalTimeWorkedSeconds / workPackage.estimatedTime;

  // changes
  function handleChange(updatedElement) {
    const newElements = apiData.data.elements.map((obj) =>
      obj.id === updatedElement.id ? updatedElement : obj
    );
    setApiData({
      ...apiData,
      data: { ...apiData.data, elements: newElements },
    });
  }

  return (
    <>
      <Header title={workPackage.title} />
      <CardGrid>
        <CardContainer>
          {workPackage.estimatedTime && (
            <Box sx={{ maxWidth: "80px" }}>
              <CircularProgressbarWithChildren
                value={workPercentage}
                maxValue={1}
                styles={{
                  path: {
                    stroke:
                      workPercentage < 1
                        ? colors.primary[500]
                        : colors.redAccent[500],
                  },
                  trail: {
                    stroke: "rgba(0, 0, 0, 0.2)",
                    strokeLinecap: "butt",
                    transform: "rotate(0.25turn)",
                    transformOrigin: "center center",
                  },
                }}
              >
                <Typography variant="h4">
                  {Math.round(workPercentage * 100)}%
                </Typography>
              </CircularProgressbarWithChildren>
            </Box>
          )}
          <Box className="flex flex-col items-center">
            <Typography variant="h6" className="pt-2 uppercase">
              {t("Worked")}
            </Typography>
            <Typography variant="h4">
              {renderHours(
                (activeWorkpackage
                  ? activeWorkpackage.totalTimeWorkedSeconds
                  : workPackage.totalTimeWorkedSeconds) / 60
              )}
              h
            </Typography>
            <Typography variant="h4"></Typography>
          </Box>
        </CardContainer>
        <CardContainer span={2}>
          <RenderHTML HTML={workPackage.description} />
        </CardContainer>
        <CardContainer>
          <ProjectUserStack
            workPackage={workPackage}
            users={workPackage.users}
          />
        </CardContainer>
      </CardGrid>
      <Box className="flex flex-col gap-2 pt-4">
        {workPackage.elements.map((element) =>
          createElement(WPEMap[element.type], {
            element,
            key: element.id,
            onChange: handleChange,
          })
        )}
      </Box>
    </>
  );
};
