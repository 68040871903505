import React from "react";
import { motion } from "framer-motion";
import { Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

export const ItemToUpload = ({ file, idx, onRemove }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  return (
    <Button
      onClick={() => onRemove(file)}
      className="w-full"
      sx={{
        padding: 0,
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "transparent",
        backgroundColor: colors.bgInput,
        ":hover": {
          backgroundColor: colors.error + "30",
          borderColor: colors.error,
        },
      }}
    >
      <motion.div
        key={"file" + idx}
        layoutId={idx === 0 ? "file-upload" : "file-upload-" + idx}
        className={cn(
          "relative overflow-hidden flex flex-col items-start justify-start md:h-24 p-4 w-full mx-auto ",
          "shadow-sm"
        )}
      >
        <div className="flex justify-between w-full items-center gap-4">
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} layout>
            <Typography>{file.name}</Typography>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            layout
            className="rounded-lg px-2 py-1 w-fit flex-shrink-0  shadow-input"
            style={{ backgroundColor: colors.selected }}
          >
            <Typography>{(file.size / (1024 * 1024)).toFixed(2)} MB</Typography>
          </motion.div>
        </div>

        <div className="flex text-sm md:flex-row flex-col items-start md:items-center w-full mt-2 justify-between ">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            layout
            className="px-1 py-0.5 rounded-md"
            style={{ backgroundColor: colors.selected }}
          >
            <Typography>{file.type}</Typography>
          </motion.div>

          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} layout>
            <Typography variant="h6">
              {t("modified")} {new Date(file.lastModified).toLocaleDateString()}
            </Typography>
          </motion.div>
        </div>
      </motion.div>
    </Button>
  );
};

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}
