import { useTheme, Box, Typography } from "@mui/material";
import {
  createElement,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import { tokens } from "../../../global/theme/tokens";
import UploadPreview from "./UploadPreview";
import { useTranslation } from "react-i18next";

const UploadWrapper = forwardRef(
  (
    {
      onUpload,
      children,
      fileType,
      previewType,
      noBackground,
      UploadPreviewElement = UploadPreview,
    },
    ref
  ) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
    const { t } = useTranslation();
    const [filesToUpload, setFilesToUpload] = useState([]);

    const handleDrop = useCallback(
      (newFiles) => {
        setFilesToUpload([...filesToUpload, ...newFiles]);
      },
      [filesToUpload]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: handleDrop,
      noClick: true,
      multiple: true, // Enable multiple file selection
    });

    function handleRemoveItem(itemToRemove) {
      const updatedFilesToUpload = filesToUpload.filter(
        (file) => file.path !== itemToRemove.path
      );
      setFilesToUpload(updatedFilesToUpload);
    }

    // exposed functions

    useImperativeHandle(ref, () => ({
      clickFileInput: handleFileInputClick,
    }));

    const inputRef = useRef(null);
    function handleFileInputClick() {
      if (inputRef !== null && inputRef.current) {
        inputRef.current.click();
      }
    }

    return (
      <Box
        className="rounded-lg relative h-full flex overflow-hidden w-full "
        {...getRootProps()}
        sx={{
          transition: "background-color 0.2s ease-out",
        }}
      >
        {children}

        <input {...getInputProps({ multiple: true, ref: inputRef })} />
        {isDragActive && (
          <Box
            className="absolute top-0 left-0 h-full w-full flex justify-center items-center backdrop-blur-lg z-10"
            sx={{
              backgroundColor: noBackground ? "transparent" : colors.glass,
              transition: "background-color 0.2s ease-out",
            }}
          >
            <Typography variant="h2">{t("Drop the files here")}</Typography>
          </Box>
        )}
        {filesToUpload.length > 0 && (
          <Box className=" absolute top-0 left-0 flex flex-col h-full w-full backdrop-blur-lg p-2 z-30">
            {createElement(UploadPreviewElement, {
              noBackground,
              previewType,
              fileType,
              filesToUpload,
              onUpload,
              onCancle: () => setFilesToUpload([]),
              onRemove: handleRemoveItem,
            })}
          </Box>
        )}
      </Box>
    );
  }
);

export default UploadWrapper;
