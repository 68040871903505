import { useTranslation } from "react-i18next";
import { API_PATCH, API_POST, DBRequest, useConfig } from "../../api/api";
import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import StepCustomStartUp from "../steps/StepCustomStartUp";
import ExoStepper, { FinalPage } from "../exo/ExoStepper";
import findDifferences from "../exo/findDifferencesInDataCollection";
import ExoDialog from "../exo/ExoDialog";
import ExoAlert from "../exo/ExoAlert";

const cookies = new Cookies();

const debugData = {
  address: {
    city: "Bumsenhausen",
    country: "ditschland",
    houseNumber: "69",
    street: "Lappenallee",
    zipCode: "89898",
  },
  bic: "MALADE51KLK",
  birthday: "1999-01-01",
  birthplace: "lautreeee",
  familyStatus: "ledig ",
  gender: "Apache Attack Helicopter",
  healthInsurance: "Techniker",
  highestDegree: "Abitur/Fachabitur",
  highestProfessionalEducation: "Promotion",
  iban: "DE15 1515 1515 1515 55",
  insuranceNumber: "132154654H55",
  nationality: "deitsch",
  parentalStatus: "1",
  privateMail: "porn@maskill.de",
  privatePhone: "123456465",
  religion: "buhtist",
  severelyDisabled: "1",
  taxClass: "1",
  taxId: "19283901823901",
  taxOfficeNumber: "Jlkgh820983829",
  userId: "3",
};

const NewUserPersonalData = ({ startDataCollection, open, debug }) => {
  // employee-info
  const config = useConfig();
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const userId = cookies.get("userId");

  const contactFields = [
    {
      label: t("Address"),
      key: "address",
      type: "address",
    },
    {
      label: t("Private Mail"),
      key: "privateMail",
      type: "text",
    },
    {
      label: t("Private Phone"),
      key: "privatePhone",
      type: "text",
    },
  ];
  const contactValidationSchema = Yup.object().shape({
    address: Yup.object().shape({
      street: Yup.string().required(t("is required")),
    }),
    privateMail: Yup.string()
      .email(t("Invalid E-Mail"))
      .required(t("is required")),
    privatePhone: Yup.string().required(t("is required")),
  });
  const privateFields = [
    {
      label: t("Gender"),
      key: "gender",
      type: "text",
    },
    {
      label: t("Birthday"),
      key: "birthday",
      type: "date",
    },
    {
      label: t("Birthplace"),
      key: "birthplace",
      type: "text",
    },
    {
      label: t("Nationality"),
      key: "nationality",
      type: "text",
    },
    {
      label: t("Family Status"),
      key: "familyStatus",
      type: "text",
    },
    {
      label: t("Parental Status"),
      key: "parentalStatus",
      type: "checkbox",
    },
    {
      label: t("Severely Disabled"),
      key: "severelyDisabled",
      type: "checkbox",
    },
    {
      label: t("Religion"),
      key: "religion",
      type: "text",
    },
  ];
  const privateValidationSchema = Yup.object().shape({
    birthday: Yup.string().required(t("is required")),
    gender: Yup.string().required(t("is required")),
    familyStatus: Yup.string().required(t("is required")),
    birthplace: Yup.string().required(t("is required")),
    religion: Yup.string().required(t("is required")),
    nationality: Yup.string().required(t("is required")),
  });
  const apprenticeshipFields = [
    {
      label: t("Highest Degree"),
      key: "highestDegree",
      type: "customSelect",
      options: [
        {
          label: t("Ohne Schulabschluss"),
          value: "Ohne Schulabschluss",
        },
        {
          label: t("Haupt-/Volksschulabschluss"),
          value: "Haupt-/Volksschulabschluss",
        },
        {
          label: t("Mittlere Reife/gleichwertiger Abschluss"),
          value: "Mittlere Reife/gleichwertiger Abschluss",
        },
        {
          label: t("Abitur/Fachabitur"),
          value: "Abitur/Fachabitur",
        },
      ],
    },
    {
      label: t("Heighest Professional Education"),
      key: "highestProfessionalEducation",
      type: "customSelect",
      options: [
        {
          label: t("Ohne beruflichen Ausbildungsabschluss"),
          value: "Ohne beruflichen Ausbildungsabschluss",
        },
        {
          label: t("Anerkannte Berufsausbildung"),
          value: "Anerkannte Berufsausbildung",
        },
        {
          label: t("Meister/Techniker/gleichwertiger Fachschulabschluss"),
          value: "Meister/Techniker/gleichwertiger Fachschulabschluss",
        },
        {
          label: t("Bachelor"),
          value: "Bachelor",
        },
        {
          label: t("Diplom/Magister/ Master/Staatsexamen"),
          value: "Diplom/Magister/ Master/Staatsexamen",
        },
        {
          label: t("Promotion"),
          value: "Promotion",
        },
      ],
    },
  ];
  const apprenticeshipValidationSchema = Yup.object().shape({
    highestDegree: Yup.string().required(t("is required")),
    highestProfessionalEducation: Yup.string().required(t("is required")),
  });

  const bankFields = [
    {
      label: t("IBAN"),
      key: "iban",
      type: "iban",
    },
    {
      label: t("BIC"),
      key: "bic",
      type: "text",
    },
  ];
  const bankValidationSchema = Yup.object().shape({
    iban: Yup.string().required(t("is required")),
    bic: Yup.string().required(t("is required")),
  });
  const taxFields = [
    {
      label: t("Tax Office Number"),
      key: "taxOfficeNumber",
      type: "text",
    },
    {
      label: t("Tax Class"),
      key: "taxClass",
      type: "text",
    },
    {
      label: t("Tax Id"),
      key: "taxId",
      type: "text",
    },
  ];
  const taxValidationSchema = Yup.object().shape({
    taxId: Yup.string().required(t("is required")),
    taxOfficeNumber: Yup.string().required(t("is required")),
    taxClass: Yup.string().required(t("is required")),
  });
  const insuranceFields = [
    {
      label: t("Health Insurance"),
      key: "healthInsurance",
      type: "text",
    },
    {
      label: t("Insurance Number"),
      key: "insuranceNumber",
      type: "text",
    },
  ];
  const insuranceValidationSchema = Yup.object().shape({
    healthInsurance: Yup.string().required(t("is required")),
    insuranceNumber: Yup.string().required(t("is required")),
  });
  const personalSteps = [
    {
      label: t("Contact"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepCustomStartUp
          fields={contactFields}
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          validationSchema={contactValidationSchema}
        />
      ),
    },
    {
      label: t("Private"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepCustomStartUp
          fields={privateFields}
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          validationSchema={privateValidationSchema}
        />
      ),
    },
    {
      label: t("Apprenticeship"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepCustomStartUp
          fields={apprenticeshipFields}
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          validationSchema={apprenticeshipValidationSchema}
        />
      ),
    },
    {
      label: t("Bank"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepCustomStartUp
          fields={bankFields}
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          validationSchema={bankValidationSchema}
        />
      ),
    },
    {
      label: t("Tax"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepCustomStartUp
          fields={taxFields}
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          validationSchema={taxValidationSchema}
        />
      ),
    },
    {
      label: t("Social Insurance"),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepCustomStartUp
          fields={insuranceFields}
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          validationSchema={insuranceValidationSchema}
        />
      ),
    },
  ];

  const finalStep = {
    operation: ({ dataCollection, setIsLoading, handleError }) => {
      setIsLoading(true);

      const diff = findDifferences(startDataCollection, dataCollection);
      const isUpdate = Boolean(
        startDataCollection ? startDataCollection.userId : null
      );
      DBRequest({
        config,
        path: isUpdate
          ? `employee-info/${dataCollection.userId}`
          : "employee-info",
        method: isUpdate
          ? API_PATCH({ ...diff })
          : API_POST({ ...dataCollection, userId }),
        onResponse: handleResponse,
        onLoading: setIsLoading,
        onError: handleError,
      });
    }, // your operation with the data
    content: (response) => <FinalPage response={response} />, // component with final operations and the feedback
  };

  function handleResponse() {
    cookies.set("personalData", "true", { path: "/" });
    setOpenDialog(false);
  }

  // alert
  const [openAlert, setOpenAlert] = useState(debug || false);
  const alert = {
    message: t(
      "You have not yet completed the personnel questionnaire. Your employer requires further data from you. Please complete the questionnaire as soon as possible."
    ),
    cancleLabel: t("Do it Later"),
    submitLabel: t("Do it Now"),
  };

  useEffect(() => {
    const hasPersonalData = !Boolean(cookies.get("personalData") === "false");
    setOpenAlert(debug || !hasPersonalData);
  }, [cookies]);

  return (
    <>
      <ExoAlert
        alert={alert}
        open={openAlert}
        onSubmit={() => {
          setOpenAlert(false);
          setOpenDialog(true);
        }}
        onClose={() => setOpenAlert(false)}
      />
      <ExoDialog open={openDialog}>
        <ExoStepper
          draftType={"personal-data"}
          startDataCollection={debug ? debugData : startDataCollection || {}}
          label={t("Staff Questionnaire")}
          data={personalSteps}
          finalStep={finalStep}
          onClose={() => setOpenDialog(false)}
        />
      </ExoDialog>
    </>
  );
};

export default NewUserPersonalData;
