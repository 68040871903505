import { useRef, useState } from "react";
import { DBUploadData, useConfig } from "../../../../../api/api";
import UploadWrapper from "../../../../../components/exo/explorer/UploadWrapper";
import {
  Box,
  Button,
  LinearProgress,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import { ItemToUpload } from "./ItemToUpload";
import TypeCustomSelect from "../../../../../components/form/form-fields/TypeCustomSelect";
import { FileItem } from "./FileItem";
import { WPEContainer } from "../WPEContainer";
import ExoProcessing from "../../../../../components/exo/ExoProcessing";
import { FormRenameFile } from "./FormRenameFile";

export const WPETypeFile = ({ element, onChange, onDelete }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  const uploadRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const statusArray = element.folderStatusMap || [];

  const containerVariants = {
    visible: {
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  function handleUploadedFile(files) {
    const newFiles = [...element.files, ...files.data.data];
    onChange({ ...element, files: newFiles });
  }

  function handleFileChange(res, oldPath) {
    const newFiles = element.files.map((obj) =>
      obj.path === oldPath ? res.data : obj
    );
    onChange({ ...element, files: newFiles });
  }

  function handleFileDelete(item) {
    const newFiles = element.files.filter((obj) => obj.path !== item.path);
    onChange({ ...element, files: newFiles });
  }

  // upload via click
  function handleUploadClick() {
    if (uploadRef && uploadRef.current) {
      uploadRef.current.clickFileInput();
    }
  }

  // edit

  const [editFile, setEditFile] = useState(null);

  const UploadPreview = ({
    filesToUpload,
    onRemove,
    onUpload,
    onCancle,
    fileType,
    previewType,
    noBackground,
  }) => {
    const [progress, setProgress] = useState(100);
    const [finished, setFinished] = useState(true);
    const [alert, setAlert] = useState(null);

    function handleUpload() {
      setAlert(null);
      const formData = new FormData();

      if (fileType) formData.append(`fileType`, fileType);

      filesToUpload.forEach((file, index) => {
        formData.append(`files[]`, file); // Use a unique field name for each file
      });

      formData.append(`status`, status);

      DBUploadData({
        config,
        path: `work-packages/elements/file/${element.id}/file`,
        formData: formData,
        onProgressChange: setProgress,
        onFinished: setFinished,
        onResponse: handleFinishedUpload,
        onError: handleError,
      });
    }

    function handleError(err) {
      setFinished(true);
      setAlert({ type: "error", message: err.response.data.message });
    }

    function handleFinishedUpload(data) {
      onUpload(data);
      onCancle();
    }

    // status
    const [status, setStatus] = useState("");
    function handleStatusChange(e, v) {
      setStatus(v);
    }

    const options = statusArray.map((obj) => {
      return { label: obj.label, value: obj.label };
    });

    return (
      <Box
        className="h-full w-full flex flex-col gap-2"
        sx={{ height: "600px", maxHeight: "100%" }}
      >
        {!finished ? (
          <Box
            className="flex flex-row gap-2 relative justify-center items-center h-full"
            sx={{
              height: "80vh",
              width: "90vw",
            }}
          >
            {progress < 100 ? (
              <Box className="flex flex-col gap-4 justify-center items-center w-full">
                <Box sx={{ width: "80%", maxWidth: "400px" }}>
                  <LinearProgress
                    variant="determinate"
                    value={Math.round(progress)}
                    color="primary"
                  />
                </Box>
                <Typography variant="h3">
                  {t("Uploading")} {Math.round(progress)}%
                </Typography>
              </Box>
            ) : (
              <Box className="flex flex-col gap-4 justify-center items-center">
                <span className="loader"></span>
                <Typography variant="h3">{t("Processing Upload")}</Typography>
              </Box>
            )}
          </Box>
        ) : (
          <>
            {options && Boolean(options.length) && (
              <TypeCustomSelect
                field={{ label: t("Status"), key: "status", options }}
                dataCollection={{ status }}
                updateValidationOnChange={handleStatusChange}
                errors={{}}
              />
            )}
            <Box className="h-full flex flex-col gap-1 overflow-y-auto">
              {filesToUpload.map((item, index) => (
                <ItemToUpload
                  key={index}
                  file={item}
                  idx={index}
                  onRemove={onRemove}
                />
              ))}
            </Box>
          </>
        )}
        <Box className="w-full flex justify-between">
          <Button variant="outlined" color="error" onClick={onCancle}>
            {t("Cancle")}
          </Button>
          <Button variant="contained" color="success" onClick={handleUpload}>
            {t("Upload")}
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <UploadWrapper
      ref={uploadRef}
      onUpload={handleUploadedFile}
      noBackground
      UploadPreviewElement={UploadPreview}
    >
      <Button
        onClick={handleUploadClick}
        sx={{ position: "absolute" }}
        className="top-1 right-1 z-10"
      >
        {t("Upload")}
      </Button>
      <WPEContainer
        title={element.name}
        sx={{ minHeight: "300px" }}
        className="w-full flex flex-col gap-1"
        framerVariants={containerVariants}
      >
        {element.files &&
          element.files.map((item, index) => (
            <FileItem
              key={item.temporaryUrl}
              item={item}
              index={index}
              wpe={element}
              onChange={handleFileChange}
              onDelete={handleFileDelete}
              onLoading={setIsLoading}
              onEdit={setEditFile}
              hasStatus={Boolean(element.folderStatusMap)}
            />
          ))}
        <ExoProcessing open={isLoading} label={t("Changing Status")} />
      </WPEContainer>
      <FormRenameFile
        open={Boolean(editFile)}
        startData={editFile}
        wpe={element}
        onChange={handleFileChange}
        onClose={() => setEditFile(null)}
      />
    </UploadWrapper>
  );
};
