import { createElement, useEffect, useState } from "react";
import TypeAccessRoleId from "./form-fields/TypeAccessRoleId";
import TypeProductCategoryIds from "./form-fields/TypeProductCategoryIds";
import TypeDescription from "./form-fields/TypeDescription";
import TypeServiceCategoryIds from "./form-fields/TypeServiceCategoryIds";
import TypeLogo from "./form-fields/TypeLogo";
import TypeReceipt from "./form-fields/TypeReceipt";
import TypeImage from "./form-fields/TypeImage";
import TypeCompanyId from "./form-fields/TypeCompanyId";
import TypeDate from "./form-fields/TypeDate";
import TypeText from "./form-fields/TypeText";
import TypeColor from "./form-fields/TypeColor";
import TypeAddress from "./form-fields/TypeAddress";
import TypeIndustryId from "./form-fields/TypeIndustryId";
import * as Yup from "yup";
import TypeTransaction from "./form-fields/TypeTransaction";
import TypeHidden from "./form-fields/TypeHidden";
import TypeCheckbox from "./form-fields/TypeCheckbox";
import TypePresetVarType from "./form-fields/TypePresetVarType";
import TypeProjectId from "./form-fields/TypeProjectId";
import TypeDateRange from "./form-fields/TypeDateRange";
import TypeDateTimeRange from "./form-fields/TypeDateTimeRange";
import TypeProfilePicture from "./form-fields/TypeProfilePicture";
import TypeClient from "./form-fields/TypeClient";
import TypeContactId from "./form-fields/TypeContactId";
import TypeMoney from "./form-fields/TypeMoney";
import TypeRhythm from "./form-fields/TypeRhythm";
import TypeDivider from "./form-fields/TypeDivider";
import TypeCustomSelect from "./form-fields/TypeCustomSelect";
import TypeReceiptPosition from "./form-fields/TypeReceiptPosition";
import TypeTaxAssignmentId from "./form-fields/TypeTaxAssignmentId";
import TypeRichText from "./form-fields/TypeRichText";
import TypeTaxRate from "./form-fields/TypeTaxRate";
import TypeImages from "./form-fields/TypeImages";
import TypeTime from "./form-fields/TypeTime";
import TypeTaskGroup from "./form-fields/TypeTaskGroup";
import TypeProject from "./form-fields/TypeProject";
import TypeInvoice from "./form-fields/TypeInvoice";
import TypeDelivery from "./form-fields/TypeDelivery";
import TypeCostCenter from "./form-fields/TypeCostCenter";
import TypeMailSelect from "./form-fields/TypeMailSelect";
import TypePassword from "./form-fields/TypePassword";
import TypeCustomMultiSelect from "./form-fields/TypeCustomMultiSelect";
import TypeAccountingPeriod from "./form-fields/TypeAccountingPeriod";
import TypeUser from "./form-fields/TypeUser";
import TypeIban from "./form-fields/TypeIban";
import { TypeHours } from "./form-fields/TypeHours";
import TypeIcon from "./form-fields/TypeIcon";
import { TypeCheckConnection } from "./form-fields/TypeCheckConnection";
import { TypeNetworkStorage } from "./form-fields/TypeNetworkStorage";
import TypeWPEType from "./form-fields/TypeWPEType";
import { TypeFolderStatus } from "./form-fields/TypeFolderStatus";
import { TypeFileTypeId } from "./form-fields/TypeFileTypeId";
import TypePhase from "./form-fields/TypePhase";
import TypeWorkPackage from "./form-fields/TypeWorkPackage";
import TypeNameValueArray from "./form-fields/TypeNameValueArray";
import { TypeTemplate } from "./form-fields/TypeTemplate";
import TypeNumber from "./form-fields/TypeNumber";

const demoSchema = Yup.object().shape({
  date: Yup.string().required("Date is required"),
  dateRange: Yup.object().shape({
    startDate: Yup.string().required("Start Date id is required"),
    endDate: Yup.string().required("End Date id is required"),
  }),
  dateTimeRange: Yup.object().shape({
    startDateTime: Yup.string().required("Start Date id is required"),
    endDateTime: Yup.string().required("End Date id is required"),
  }),
  text: Yup.string().required("Text is required"),
  color: Yup.string().required("Color is required"),
  logo: Yup.object().shape({
    id: Yup.string().required("Logo id is required"),
  }),
  accessId: Yup.string().required("Access is required"),
  productCategoryIds: Yup.array()
    .min(1, "Category must have at least one item")
    .required("Category is required"),
  serviceCategoryIds: Yup.array()
    .min(1, "Category must have at least one item")
    .required("Category is required"),
  description: Yup.string().required("Description is required"),
  companyId: Yup.string().required("Company is required"),
  receipt: Yup.object().shape({
    id: Yup.string().required("Receipt is required"),
  }),
  image: Yup.object().shape({
    id: Yup.string().required("Image is required"),
  }),
  profilePicture: Yup.object().shape({
    id: Yup.string().required("Profile Picture is required"),
  }),
  address: Yup.object().shape({
    street: Yup.string().required("Address id is required"),
  }),
  industryId: Yup.number().required("Industry is required"),
  userId: Yup.number().required("User is required"),
  projectId: Yup.number().required("Project is required"),
});

const typeMap = {
  template: TypeTemplate,
  networkStorage: TypeNetworkStorage,
  date: TypeDate,
  text: TypeText,
  money: TypeMoney,
  color: TypeColor,
  address: TypeAddress,
  logo: TypeLogo,
  accessId: TypeAccessRoleId,
  productCategoryIds: TypeProductCategoryIds,
  serviceCategoryIds: TypeServiceCategoryIds,
  description: TypeDescription,
  companyId: TypeCompanyId,
  contactId: TypeContactId,
  projectId: TypeProjectId,
  project: TypeProject,
  receipt: TypeReceipt,
  image: TypeImage,
  images: TypeImages,
  industryId: TypeIndustryId,
  transaction: TypeTransaction,
  checkbox: TypeCheckbox,
  hidden: TypeHidden,
  presetVarType: TypePresetVarType,
  dateRange: TypeDateRange,
  dateTimeRange: TypeDateTimeRange,
  user: TypeUser,
  profilePicture: TypeProfilePicture,
  client: TypeClient,
  rhythm: TypeRhythm,
  divider: TypeDivider,
  customSelect: TypeCustomSelect,
  receiptPosition: TypeReceiptPosition,
  taxAssignmentId: TypeTaxAssignmentId,
  richText: TypeRichText,
  taxRate: TypeTaxRate,
  time: TypeTime,
  taskGroup: TypeTaskGroup,
  invoice: TypeInvoice,
  delivery: TypeDelivery,
  costCenter: TypeCostCenter,
  mailSelect: TypeMailSelect,
  password: TypePassword,
  customMultiSelect: TypeCustomMultiSelect,
  accountingPeriod: TypeAccountingPeriod,
  iban: TypeIban,
  hours: TypeHours,
  icon: TypeIcon,
  checkConnection: TypeCheckConnection,
  seType: TypeWPEType,
  folderStatus: TypeFolderStatus,
  fileTypeId: TypeFileTypeId,
  phase: TypePhase,
  workPackage: TypeWorkPackage,
  nameValueArray: TypeNameValueArray,
  number: TypeNumber,
};

const FormFields = ({
  fields,
  updateDataCollection,
  dataCollection,
  validationSchema = Yup.object().shape(),
  startDataCollection,
  onPreventEnter,
}) => {
  const [fieldElements, setFieldElements] = useState([]);

  useEffect(() => {
    if (!fields) {
      const newFields = [];
      const keys = Object.keys(typeMap);
      keys.forEach((key) => {
        newFields.push({
          label: key,
          key: key,
          type: key,

          options:
            key === "delivery"
              ? []
              : [
                  { label: "TEST-1", value: 1 },
                  { label: "TEST-2", value: 2 },
                ],
          projectKey: "project",
        });
      });
      setFieldElements(newFields);
    } else {
      setFieldElements(fields);
    }
  }, [fields]);

  const [errors, setErrors] = useState({});

  async function updateValidationOnChange(
    key,
    fieldValue,
    destructObject = false
  ) {
    if (destructObject) {
      const valueToCheck = fieldValue[key];
      validateField(key, valueToCheck);
      updateDataCollection({ ...fieldValue });
    } else {
      validateField(key, fieldValue);
      updateDataCollection({ [key]: fieldValue });
    }
  }

  // validate every field onChange!
  const validateField = async (fieldKey, fieldValue) => {
    try {
      const fieldSchema = Yup.object().shape({
        [fieldKey]: validationSchema.fields[fieldKey],
      });
      await fieldSchema.validate({ [fieldKey]: fieldValue });
      // Validation passed for the specific field

      // clear the error
      const newErrors = { ...errors };
      delete newErrors[fieldKey];
      setErrors(newErrors);
    } catch (err) {
      // Validation failed for the specific field
      if (err instanceof Yup.ValidationError) {
        setErrors({ ...errors, [fieldKey]: err.message });
      }
    }
  };

  return (
    <>
      {fieldElements.map((field, index) => {
        if (!typeMap[field.type])
          return console.error(`INVALIDE Form Field Type: ${field.type}`);
        return createElement(typeMap[field.type], {
          dataCollection: dataCollection || {},
          startDataCollection,
          field,
          errors,
          updateValidationOnChange,
          key: index,
          onPreventEnter: onPreventEnter,
        });
      })}
    </>
  );
};

export default FormFields;
