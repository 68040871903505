import { Box, IconButton, Typography } from "@mui/material";
import ExoTable from "../ExoTable";
import { useTranslation } from "react-i18next";
import DateLabel from "../../label/DateLabel";
import useFetch from "../../../api/useFetch";
import ExoDialog from "../ExoDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import { API_DELETE, DBRequest, useConfig } from "../../../api/api";
import { useEffect, useState } from "react";
import DateTimeLabel from "../../label/DateTimeLabel";

const DraftHistory = ({
  onSelect,
  draftType,
  open,
  onClose,
  setBadgeCount,
}) => {
  const config = useConfig();

  const { apiData, setApiData } = useFetch(`drafts?type[eq]=${draftType}`);

  const { t } = useTranslation();
  const columns = [
    {
      header: t("Title"), // header of column
      key: "title", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "100%", // width of column
      cell: ({ row }) => (
        <Typography>{row.dataCollection.title || t("Untitled")}</Typography>
      ),
      onClick: (row, column) => onSelect(row.dataCollection), // function on cell click
    },
    {
      header: t("Date"), // header of column
      key: "date", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      sortable: true, // enable sort function for column
      width: "150px", // width of column
      cell: ({ row }) => (
        <Typography className="monospace">
          <DateTimeLabel dateTime={row.updatedAt} />
        </Typography>
      ), // custom xml for cells in the column. you get the row as object
      onClick: (row, column) => onSelect(row.dataCollection), // function on cell click
    },
    {
      header: "",
      key: "id",
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      width: "21px", // width of column
      cell: ({ row }) => <DeleteIcon />, // custom xml for cells in the column. you get the row as object
      onClick: (row, column) => handleDelete(row), // function on cell click
    },
  ];

  function handleDelete(element) {
    DBRequest({
      config,
      path: `drafts/${element.id}`,
      method: API_DELETE,
      onResponse: () => handleDeletedElement(element),
    });
  }

  function handleDeletedElement(removedElement) {
    const newApiData = apiData.data.filter(
      (item) => item.id !== removedElement.id
    );
    setApiData({ ...apiData, data: [...newApiData] });
  }

  // draft count
  useEffect(() => {
    recalculateDraftCount();
    console.log("CHANGE");
  }, [apiData]);

  function recalculateDraftCount() {
    if (apiData && apiData.data) {
      const newDraftCount = apiData.data.length;
      setBadgeCount(newDraftCount);
    }
  }

  return (
    <ExoDialog open={open} onClose={onClose}>
      <Box className="flex flex-col h-full">
        <Typography variant="h2" textAlign={"center"}>
          {t("Drafts")}
        </Typography>
        <ExoTable
          data={apiData}
          columns={columns}
          isLoading={!apiData}
          onDelete={handleDelete}
        />
      </Box>
    </ExoDialog>
  );
};

export default DraftHistory;
