import { useTheme, Box, IconButton, Typography, Button } from "@mui/material";
import {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { tokens } from "../../global/theme/tokens";
import { useDropzone } from "react-dropzone";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useTranslation } from "react-i18next";
import PDFPreview from "../special/PDFPreview";
import { AiProcessing } from "../special/AiProcessing";

const SingleFileUpload = forwardRef(
  ({ setFile = () => {}, label, debug, aiIsRunning }, ref) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
    const { t } = useTranslation();

    function handleReset() {
      setFileData({});
    }

    const [fileData, setFileData] = useState({});

    useImperativeHandle(ref, () => ({
      reset: handleReset,
      setFileData: setFileData,
    }));

    const handleDrop = useCallback(
      (newFile) => {
        setFileData(newFile[0]);
        setFile(newFile[0]);
      },
      [fileData]
    );

    const removeFile = useCallback(() => {
      setFileData([]);
      setFile({});
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: handleDrop,
    });

    return (
      <Box
        className="flex flex-col relative items-center w-full h-full rounded-lg overflow-hidden"
        sx={{
          backgroundColor: colors.bgInput,
          maxHeight: "100%",
          maxWidth: "100%",
        }}
      >
        {debug && (
          <Button
            className="top-1 left-1 z-50"
            sx={{ position: "absolute" }}
            variant="contained"
            color="warning"
            onClick={() => console.log(fileData)}
          >
            LOG
          </Button>
        )}
        {fileData.size > 0 ? (
          <Box className="w-full h-full flex flex-col relative overflow-hidden">
            <AiProcessing
              active={aiIsRunning}
              label={t("Ai is thinking")}
              className="w-full h-full absolute top-0 left-0 z-30"
            />
            {fileData.type === "application/pdf" ? (
              <PDFPreview
                src={
                  fileData.id ? fileData.path : URL.createObjectURL(fileData)
                }
              />
            ) : (
              <img
                className="w-full h-full object-contain"
                src={
                  fileData.id ? fileData.path : URL.createObjectURL(fileData)
                }
                alt={fileData.title}
              />
            )}
            <Button
              onClick={removeFile}
              sx={{ position: "absolute" }}
              className="top-1 right-2 z-20"
              variant="contained"
            >
              <RemoveCircleOutlineIcon />
            </Button>
          </Box>
        ) : (
          <Box
            {...getRootProps()}
            className="w-full border-dashed border-2 flex items-center justify-center cursor-pointer h-full"
            sx={{
              backgroundColor: isDragActive
                ? `${colors.blueAccent[400]}30`
                : "transparent",
              borderColor: isDragActive
                ? colors.blueAccent[400]
                : colors.primary[500],
              aspectRatio: 1 / 1.4,
            }}
          >
            <input {...getInputProps()} />
            <Box className="flex flex-col justify-center items-center  py-4">
              <UploadFileIcon
                sx={{
                  fontSize: "clamp(50px, 10vw, 200px)",
                }}
              />
              <Typography
                variant="h3"
                textAlign="center"
                sx={{
                  fontSize: "clamp(15px, 1.7vw, 50px)",
                }}
              >
                {label || t("Drop your document here")}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    );
  }
);

export default SingleFileUpload;
