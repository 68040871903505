import { InputAdornment, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { numberToString, stringToNumber } from "../../special/numberConverter";
import { useConfig } from "../../../api/api";

const TypeNumber = ({
  field,
  dataCollection,
  errors,
  updateValidationOnChange,
  startDataCollection,
}) => {
  const [value, setValue] = useState(dataCollection[field.key] || "");
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (!startDataCollection || !startDataCollection[field.key]) return;
    setValue(
      numberToString(startDataCollection[field.key] || "", field.decimalPlaces)
    );
  }, [startDataCollection]);

  useEffect(() => {
    if (focused) return;
    if (dataCollection[field.key] !== undefined)
      setValue(numberToString(dataCollection[field.key]), field.decimalPlaces);
  }, [dataCollection[field.key]]);

  function handleFocus() {
    setFocused(true);
    if (!dataCollection[field.key] || dataCollection[field.key] === 0)
      setValue("");
  }

  function handleTextChange(value) {
    if (stringToNumber(value.target.value, field.decimalPlaces) !== 0) {
      updateValidationOnChange(
        [field.key],
        stringToNumber(value.target.value, field.decimalPlaces)
      );
    }
    setValue(value.target.value);
  }

  function handleBlur(value) {
    setFocused(false);
    if (stringToNumber(value.target.value, field.decimalPlaces) !== 0) {
      updateValidationOnChange(
        [field.key],
        stringToNumber(value.target.value, field.decimalPlaces)
      );
      setValue(
        numberToString(
          stringToNumber(value.target.value, field.decimalPlaces),
          field.decimalPlaces
        )
      );
    }
  }

  return (
    <TextField
      label={field.label}
      value={value}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onChange={handleTextChange}
      multiline={Boolean(field.multiline)}
      error={Boolean(errors[field.key])}
      helperText={errors[field.key]}
    />
  );
};

export default TypeNumber;
